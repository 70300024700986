import * as React from 'react';
import { EntityEdit } from 'pradmin';
import UserForm from './user.form';

export default function UserEdit() {
  return (
    <EntityEdit resource="users">
      <UserForm />
    </EntityEdit>
  );
}
