import * as React from 'react';
import { EntityEdit } from 'pradmin';
import ShopifyProductForm from './shopify-product.form';

export default function ShopifyProductEdit() {
  return (
    <EntityEdit resource="shopify_products">
      <ShopifyProductForm />
    </EntityEdit>
  );
}
