import * as React from 'react';
import {
  DataContext, EntityForm, AdminTextField, AdminChipField,
} from 'pradmin';

export default function UserForm({ buttons }) {
  const [data] = React.useContext(DataContext);

  return (
    <EntityForm
      resource="users"
      buttons={buttons}
    >
      {data.id && <AdminTextField name="id" disabled />}
      <AdminTextField name="email" required />
      <AdminChipField valueField="roles" />
      <AdminTextField name="plainPassword" required />
    </EntityForm>
  );
}
