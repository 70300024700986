import * as React from 'react';
import {
  Button, Stack, TextField,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useContext, useRef } from 'react';
import { AdminResourceSelectField, DataContext } from 'pradmin/dist';

export default function ShopifyProductMappingForm() {
  const [data, setData] = useContext(DataContext);
  const nextId = useRef(0);

  const getNextMappingId = () => {
    nextId.current += 1;
    return `$new-${nextId.current}`;
  };

  const getMappingId = (mapping) => ('id' in mapping ? mapping.id : mapping.helperId);

  const getAmountInput = (newValue) => (newValue.toString().match('[1-9][0-9]*') ? parseInt(newValue, 10) : '');

  const handleAmountInput = (mappingId, variantId) => ((e) => {
    const { value: newValue } = e.target;
    setData({
      ...data,
      variants: data.variants.map((variant) => (
        variant.id !== variantId ? variant
          : {
            ...variant,
            bqMappings: variant.bqMappings.map((mapping) => (
              mappingId !== getMappingId(mapping) ? mapping
                : { ...mapping, amount: getAmountInput(newValue) }
            )),
          }
      )),
    });
  });

  // takes over the mappings of the first variant for all other variants
  const handleFirstMappingSynchronization = () => {
    const referenceMappings = data.variants[0].bqMappings;
    setData({
      ...data,
      variants: data.variants.map((variant) => ({
        ...variant,
        bqMappings: referenceMappings.map((newMapping) => (
          {
            helperId: getNextMappingId(),
            amount: newMapping.amount,
            bqItem: { id: newMapping.bqItem.id, name: newMapping.bqItem.name },
          }
        )),
      })),
    });
  };

  const getBqItemSetter = (mapping, variantId) => (bqItem) => {
    setData({
      ...data,
      variants: data.variants.map((v) => (
        v.id !== variantId ? v
          : {
            ...v,
            bqMappings: v.bqMappings.map((m) => (
              getMappingId(m) !== getMappingId(mapping) ? m
                : { ...mapping, bqItem: { id: bqItem } }
            )),
          })),
    });
  };

  const handleNewMapping = (variantId) => () => {
    setData({
      ...data,
      variants: data.variants.map((variant) => (
        variant.id !== variantId ? variant
          : {
            ...variant,
            bqMappings: [
              ...variant.bqMappings,
              { helperId: getNextMappingId(), amount: 1 },
            ],
          }
      )),
    });
  };

  const handleDeleteMapping = (mappingId, variantId) => () => {
    setData({
      ...data,
      variants: data.variants.map((variant) => (
        variant.id !== variantId ? variant
          : {
            ...variant,
            bqMappings: variant.bqMappings.filter((m) => getMappingId(m) !== mappingId),
          }
      )),
    });
  };

  return (
    <Stack key="mapping">
      <Stack direction="row" spacing={4} alignItems="center">
        <h2>Booqable Mappings</h2>
        <Tooltip title="save the first mapping for every variant">
          <Button variant="outlined" color="secondary" sx={{ width: '35ch', height: '5ch' }} onClick={handleFirstMappingSynchronization}>
            Mappings synchronisieren
          </Button>
        </Tooltip>
      </Stack>
      {data.variants.map((variant) => (
        <Stack spacing={2} key={`${variant.title}-mapping`}>
          <h3>
            {variant.title}
          </h3>
          <Button variant="outlined" color="success" sx={{ width: '20ch' }} onClick={handleNewMapping(variant.id)}>
            Neu
          </Button>
          <br />
          {variant.bqMappings.map((mapping) => (
            <Stack direction="row" spacing={1} key={getMappingId(mapping)}>
              <AdminResourceSelectField
                name="bqItem"
                label="Booqable-Element"
                resource="bq_items"
                value={('bqItem' in mapping) ? (mapping.bqItem.id) : ''}
                setValue={getBqItemSetter(mapping, variant.id)}
                size="small"
                sx={{ width: '60ch' }}
                key={`bq-item-${getMappingId(mapping)}`}
                comparator={(a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)}
                required
              />
              <TextField
                label="amount"
                value={mapping.amount ?? 1}
                size="medium"
                onChange={handleAmountInput(getMappingId(mapping), variant.id)}
                inputProps={{ pattern: '[1-9][0-9]*' }}
                key={`amount-${getMappingId(mapping)}`}
                required
              />
              <Button variant="outlined" color="error" onClick={handleDeleteMapping(getMappingId(mapping), variant.id)}>
                Löschen
              </Button>
            </Stack>
          ))}
        </Stack>
      ))}
    </Stack>
  );
}
