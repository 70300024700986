import {
  List, ListItemButton, ListItemIcon, ListItemText, Stack,
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove'; import * as React from 'react';
import { useContext } from 'react';
import { DataContext } from 'pradmin';

export default function BqItemMappingsForm() {
  const [data] = useContext(DataContext);

  return (
    <Stack key="mappingStack">
      <h2>Mappings</h2>
      <List>
        { data.shopifyMappings.length
          ? data.shopifyMappings.map((m) => (
            <ListItemButton component="a" href={`/shopify_products/${m.shopifyProductVariant.shopifyProduct.id}`} key={m.shopifyProductVariant.id}>
              <ListItemIcon>
                <RemoveIcon />
              </ListItemIcon>
              <ListItemText primary={`${m.shopifyProductVariant.shopifyProduct.name} - ${m.shopifyProductVariant.title} (${m.amount})`} />
            </ListItemButton>
          ))
          : <p>Noch keine Mappings vorhanden</p>}
      </List>
    </Stack>
  );
}
