import {
  Box, Card, CardContent, Typography,
} from '@mui/material';
import * as React from 'react';

export default function NotSupported() {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Not available...
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
