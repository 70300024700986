import * as React from 'react';
import { EntityList } from 'pradmin';
import StatusIcon from './bq-item.status';
import ImportButton from '../../components/import-button';

export default function BqItemList() {
  const statusOrder = ['pending', 'acknowledged', 'mapped'];

  function statusComparator(s1, s2) {
    const posA = statusOrder.indexOf(s1);
    const posB = statusOrder.indexOf(s2);
    return posA - posB;
  }

  return (
    <EntityList
      resource="bq_items"
      label="Bq-Produkte"
      initialState={{ sorting: { sortModel: [{ field: 'status', sort: 'asc' }] } }}
      additionalButtons={(
        <ImportButton importPath="import/bq" />
      )}
      columns={[
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'name', headerName: 'Title', flex: 1 },
        {
          field: 'status',
          headerName: 'Status',
          sortComparator: statusComparator,
          renderCell: (params) => (<StatusIcon status={params.value} />),
        },
      ]}
    />
  );
}
