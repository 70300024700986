import * as React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import {
  AutoStories, ShoppingBag,
} from '@mui/icons-material';

const menu = [
  { label: 'Benutzer', path: '/users', icon: <PersonIcon /> },
  { label: 'Shopify-Produkte', path: '/shopify_products', icon: <ShoppingBag /> },
  { label: 'Booqable-Produkte', path: '/bq_items', icon: <AutoStories /> },
];

export default menu;
