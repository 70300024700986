import * as React from 'react';
import {
  EntityForm, AdminTextField, AdminCheckboxField,
} from 'pradmin';
import BqItemMappingsForm from './bq-item.form.mappings';

export default function BqItemForm({ buttons }) {
  const getCards = () => [<BqItemMappingsForm key="mappings-card" />];

  return (
    <EntityForm
      resource="bq_items"
      buttons={buttons}
      cards={getCards()}
    >
      <AdminTextField name="id" />
      <AdminTextField name="name" />
      <AdminCheckboxField name="acknowledged" label="Zur Kenntnis genommen" />
    </EntityForm>
  );
}
