import {
  Avatar, Chip, Stack,
} from '@mui/material';
import * as React from 'react';
import { EntityList } from 'pradmin';
import ImportButton from '../../components/import-button';
import StatusIcon from './shopify-product.status';

export default function ShopifyProductList() {
  const statusOrder = ['pending', 'partlyMapped', 'acknowledged', 'mapped'];

  function statusComparator(s1, s2) {
    const posA = statusOrder.indexOf(s1);
    const posB = statusOrder.indexOf(s2);
    return posA - posB;
  }

  return (
    <EntityList
      resource="shopify_products"
      label="Shopify-Produkte"
      initialState={{ sorting: { sortModel: [{ field: 'status', sort: 'asc' }] } }}
      additionalButtons={(
        <ImportButton importPath="import/shopify" />
              )}
      columns={[
        {
          field: 'photoUrl',
          headerName: 'Picture',
          flex: 0.8,
          renderCell: (params) => (
            <Avatar src={params.value} variant="square" />
          ),
        },
        { field: 'name', headerName: 'Title', flex: 2 },
        { field: 'id', headerName: 'Id', flex: 1 },
        {
          field: 'status',
          headerName: 'Status',
          flex: 1,
          sortComparator: statusComparator,
          renderCell: (params) => (<StatusIcon status={params.value} />),
        },
        {
          field: 'variants',
          headerName: 'Variants',
          flex: 3,
          renderCell: (params) => (
            <Stack direction="row" spacing={1}>
              {params.value.map((r) => (
                <Chip key={r.title} label={r.title} size="small" />
              ))}
            </Stack>
          ),
        },
      ]}
    />
  );
}
