import * as React from 'react';
import { useState } from 'react';
import { DataContext } from 'pradmin';
import UserForm from './user.form';

const defaultState = {
  email: '',
  roles: [],
  plainPassword: '',
};

export default function UserNew() {
  const dataAccessor = useState(defaultState);
  return (
    <DataContext.Provider value={dataAccessor}>
      <UserForm />
    </DataContext.Provider>
  );
}
