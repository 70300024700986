import * as React from 'react';
import { EntityEdit } from 'pradmin';
import BqItemForm from './bq-item.form';

export default function BqItemEdit() {
  return (
    <EntityEdit resource="bq_items">
      <BqItemForm />
    </EntityEdit>
  );
}
