import * as React from 'react';
import { Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckIcon from '@mui/icons-material/Check';

export default function StatusIcon({ status }) {
  switch (status) {
    case 'acknowledged':
      return <Tooltip title="Zur Kenntnis genommen"><VisibilityIcon /></Tooltip>;
    case 'pending':
      return <Tooltip title="Mapping ausstehend"><HourglassTopIcon /></Tooltip>;
    case 'mapped':
      return <Tooltip title="Mapping vorhanden"><CheckIcon /></Tooltip>;
    default:
      throw new Error('invalid status');
  }
}
