import { Chip, Stack } from '@mui/material';
import * as React from 'react';
import { EntityList } from 'pradmin';

export default function UserList() {
  return (
    <EntityList
      resource="users"
      label="Benutzer"
      columns={[
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        {
          field: 'roles',
          headerName: 'Roles',
          flex: 2,
          renderCell: (params) => (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              {params.value.map((r) => (
                <Chip key={r} label={r} />
              ))}
            </Stack>
          ),
        },
      ]}
    />
  );
}
