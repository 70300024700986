import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CrudApp, ThemeContext } from 'pradmin';

function Config() {
  const { setPalette } = React.useContext(ThemeContext);

  useEffect(() => {
    setPalette(
      {
        primary: {
          main: '#f07e00',
        },
        secondary: {
          main: '#ffb74d',
        },
      },
    );
  }, [setPalette]);

  return (null);
}

ReactDOM.render(
  <React.StrictMode>
    <CrudApp Config={Config} />
  </React.StrictMode>,
  document.getElementById('root'),
);
