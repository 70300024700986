import React from 'react';
import { EntityCrud } from 'pradmin';
import UserList from '../entity/user/user.list';
import UserNew from '../entity/user/user.new';
import UserEdit from '../entity/user/user.edit';
import ShopifyProductList from '../entity/shopify-product/shopify-product.list';
import ShopifyProductEdit from '../entity/shopify-product/shopify-product.edit';
import BqItemList from '../entity/bq-item/bq-item.list';
import BqItemEdit from '../entity/bq-item/bq-item.edit';
import NotSupported from '../components/not-supported';
import Drawer from '../components/drawer';

const drawer = <Drawer />;

const routes = [
  {
    path: 'users/*',
    element: <EntityCrud
      listComponent={<UserList />}
      newComponent={<UserNew />}
      editComponent={<UserEdit />}
      drawer={drawer}
    />,
  },
  {
    path: 'shopify_products/*',
    element: <EntityCrud
      listComponent={<ShopifyProductList />}
      newComponent={<NotSupported />}
      editComponent={<ShopifyProductEdit />}
      drawer={drawer}
    />,
  },
  {
    path: 'bq_items/*',
    element: <EntityCrud
      listComponent={<BqItemList />}
      newComponent={<NotSupported />}
      editComponent={<BqItemEdit />}
      drawer={drawer}
    />,
  },
];

export default routes;
