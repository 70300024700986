import React from 'react';
import { MiniDrawer } from 'pradmin';

export default function Drawer() {
  const logo = (
    <img
      src="/logo.png"
      alt="Logo"
      height="35px"
      style={{ paddingRight: '50px' }}
    />
  );

  const darkLogo = (
    <img
      src="/logo-black.png"
      alt="Logo"
      height="35px"
      style={{ paddingRight: '50px' }}
    />
  );

  return <MiniDrawer logo={logo} darkLogo={darkLogo} />;
}
