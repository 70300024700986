import * as React from 'react';
import { ServiceContext } from 'pradmin';
import { Warning } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function ImportButton({ importPath }) {
  const [loading, setLoading] = React.useState(false);
  const { dataService } = React.useContext(ServiceContext);
  const [importWarning, setImportWarning] = React.useState(false);

  const handleImport = () => {
    setImportWarning(false);
    if (!loading) {
      setLoading(true);
    }
    dataService.getData(importPath).then((data) => {
      if (data.class === 'Error' || data.class === 'Exception') {
        setImportWarning(true);
      } else {
        window.location.reload();
      }
      setLoading(false);
    });
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<RefreshIcon />}
        onClick={handleImport}
        disabled={loading}
      >
        Import&nbsp;
        {loading && (<CircularProgress size={24} />)}
        {importWarning && (<Warning color="inherit" />)}
      </Button>
    </Box>
  );
}
