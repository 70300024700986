import * as React from 'react';
import {
  EntityForm, AdminTextField, AdminCheckboxField,
} from 'pradmin';
import ShopifyProductMappingForm from './shopify-product.form.mapping';

export default function ShopifyProductForm({ buttons }) {
  const getCards = () => [<ShopifyProductMappingForm />];

  return (
    <EntityForm
      resource="shopify_products"
      buttons={buttons}
      cards={getCards()}
    >
      <AdminTextField name="name" disabled />
      <AdminCheckboxField name="acknowledged" label="Zur Kenntnis genommen" />
    </EntityForm>
  );
}
